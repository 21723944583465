import React, { Fragment } from 'react';
import DataGrid, { Column, Pager, Paging, FilterRow, HeaderFilter, ColumnFixing, Grouping, GroupPanel, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo, Form, Popup, Item, Editing, Field } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { rendModal, api, loading, numberFormat, saiki, tanggalIndo, exportData } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Laporan History Semua Pelanggan";
    document.title = "Laporan Semua History Pelanggan";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameCustomer",
                by: "ASC",
                Data: [],
                Field: [
                    { cap: "Pelanggan", sort: "NameAccount", type: "str" },
                    { cap: "Bulan1", sort: "Bulan1", type: "str" },
                    { cap: "Bulan2", sort: "Bulan2", type: "str" },
                    { cap: "Bulan3", sort: "Bulan3", type: "str" },
                    { cap: "Bulan4", sort: "Bulan4", type: "str" },
                    { cap: "Bulan5", sort: "Bulan5", type: "str" },
                    { cap: "Bulan6", sort: "Bulan6", type: "str" },
                ],
                ready: false,
                chkAll: false,
            };
        }

        componentDidMount() {
            loading();
            this.main();
        }

        async main() {
            let sql = await api("laporan_penjualan_all_pelanggan", { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by });
            let data = sql.data;
            let keys = Object.keys(data[0]);
            let field = [];
            for (let dd of keys) field.push({ cap: dd, sort: dd, type: "str" });
            if (sql.status == "true") this.setState({ Data: sql.data, ready: true, Field: field });
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span class="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            exportData({ data: this.state.Data, field: { thead: this.state.Field } }, `Data Pelanggan`);
            e.target.innerHTML = `<span class="material-icons">file_download</span>`;
            e.target.disabled = false;
        }

        async handleDetail(data) {
            let sql = await api("detail_history_pelanggan_all", { ID: data.data.ID });
            if (sql.status == "true") {
                let dom = (<Fragment>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Tanggal</th>
                                <th>No.Invoice</th>
                                <th>Total</th>
                                <th>Barberman</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyPelanggan">
                            {
                                sql.data.map((tr, i) => {
                                    return <tr>
                                        <td>{tanggalIndo(tr.DocDate)}</td>
                                        <td>{tr.DocNumber}</td>
                                        <td>{numberFormat(tr.GrandTotal)}</td>
                                        <td>{tr.NameOperator}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>);

                rendModal({
                    title: "Detail Transaksi Pelanggan",
                    body: dom,
                    cls: "modal-lg",
                    id: "cariPelanggan"
                });
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={() => this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} className="form-control form-control-sc form-control-sm" placeholder="Search" />
                                    <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><span className="material-icons">file_download</span></button>
                                </div>

                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="chkAll" checked={this.state.chkAll} onChange={(e) => {
                                        this.setState({ chkAll: e.target.checked });
                                        this.main();
                                    }} />
                                    <label className="form-check-label" htmlFor="chkAll">Expand Data</label>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            {
                                this.state.ready == true ?
                                    <DataGrid
                                        dataSource={this.state.Data}
                                        showBorders={true}
                                        allowColumnResizing={true}
                                        columnAutoWidth={true}
                                        rowAlternationEnabled={true}
                                        style={{ width: "100%", minWidth: "1000px" }}
                                        onRowClick={(e) => this.handleDetail(e)}
                                    >
                                        {
                                            this.state.Field.map((tr, ii) => {
                                                if (tr.type == "number") {
                                                    return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                                } else if (tr.type == "date") {
                                                    return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                                } else if (tr.type == "datetime") {
                                                    return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                                } else {
                                                    return <Column dataField={tr.sort} caption={tr.cap} />
                                                }
                                            })
                                        }

                                        <Paging defaultPageSize={500} />
                                        <FilterRow visible={true} />
                                        <HeaderFilter visible={true} />
                                        <GroupPanel visible={true} />
                                        <ColumnFixing enabled={true} />
                                        <Pager allowedPageSizes={[100, 500, 1000, 1500]} showPageSizeSelector={true} />
                                    </DataGrid>
                                    : <h5>Memuat data . . . .</h5>
                            }

                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;